@font-face {
  font-family: 'Ketchum';
  src: url('./fonts/font.woff');
}

@font-face {
  font-family: 'Pokemon Classic';
  src: url('./fonts/font2.woff');
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wowza {
  0% {
    transform: scale(1) translateX(-50%);
  }

  50% {
    transform: scale(1.1) translateX(-50%);
  }

  100% {
    transform: scale(1) translateX(-50%);
  }
}

@keyframes wowzaL {
  0% {
    transform: scale(1) rotate(-10deg);
  }
  50% {
    transform: scale(1.1) rotate(-10deg);
  }
  100% {
    transform: scale(1) rotate(-10deg);
  }
}

body {
  margin: 0;
  overflow: hidden;
  font-family: 'Ketchum', sans-serif !important;
}

canvas {
  display: block;
}

#counter {
  position: absolute;
  top: 40px;
  left: 40px;
  font-size: 48px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  animation: wowzaL 0.5s infinite;
  line-height: 0.8;
  text-align: center;
}

.no-pulsing {
  animation: none !important;
  transform: rotate(-10deg);
}

.balance {
  display: inline-block;
  font-size: 24px;
  transform: rotate(-2deg);
}

.multiplier {
  display: inline-block;
  color: #fff;
  font-size: 24px;
  color: #ffcd00;
  text-shadow: -3px 3px 0px #09579b;
  transform: rotate(-2deg);
}

.logo {
  display: block;
  width: 100%;
  max-width: 270px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5%;
}

h1 {
  display: block;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  text-align: center;
  width: 100%;
  font-family: 'Pokemon Classic', sans-serif;
}

h1 span {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
}

.heading {
  color: #fff;
  font-size: 24px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.ca {
  color: #fff;
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.badge {
  margin: 0;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  border-radius: 6px;
}

.wallet-adapter-dropdown {
  position: absolute !important;
  top: 40px;
  right: 40px;
}

.wallet-adapter-modal-list {
  margin-top: 12px !important;
}

.wallet-adapter-modal-button-close {
  top: -25px !important;
  right: 10px !important;
  z-index: 10 !important;
}

.leaderboard-button, .submit-score {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 5px 10px;
  color: #fff;
  font-size: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-family: 'Pokemon Classic', sans-serif;
}

.refresh {
  display: inline-block;
  margin-left: 5px;
  width: 10px;
  height: 10px;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.rotate {
  animation: rotate 0.5s infinite;
}

.submit-score {
  bottom: 180px;
  transform-origin: center left;
  animation: wowza 0.5s infinite;
}

.leaderboard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 10px 30px 18px;
  color: #fff;
  font-size: 14px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-family: 'Pokemon Classic', sans-serif;
  width: 430px;
  max-width: 90%;
}

.leaderboard h2 {
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  text-align: center;
}

.leaderboard p {
  margin-bottom: 10px;
  text-align: center;
}

.leaderboard li:first-child::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('./images/crown.svg') no-repeat;
  background-size: contain;
  margin-right: 7px;
  transform: translateY(3px);
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.65); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: rgba(0, 0, 0, 0.85);
  padding: 30px 40px;
  text-align: center;
  width: 530px;
  max-width: 100%;
}

.popup-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #ffcd00;
  font-size: 48px;
  text-shadow: -4px 4px 0px #09579b;
}

.popup-content ol {
  font-family: 'Pokemon Classic', sans-serif;
  color: #fff;
}

.popup-content ol {
  text-align: left;
  margin: 0;
}

.popup-content ol li:not(:last-child) {
  margin-bottom: 5px;
}

.bonus {
  color: #ffcd00;
}

.popup-content button {
  margin-top: 20px;
  padding: 7px 20px 10px 26px;
  background: #0b387a;
  font-size: 32px;
  color: #ffce00;
  text-shadow: -4px 4px 0px #09579b;
  border: none;
  cursor: pointer;
  font-family: Ketchum, sans-serif;
}

.info-button {
  position: absolute;
  bottom: 40px;
  left: 40px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px 10px;
  line-height: 1;
  cursor: pointer;
  z-index: 10;
}

.settings-button {
  position: absolute;
  bottom: 40px;
  right: 40px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px 10px;
  line-height: 1;
  cursor: pointer;
  z-index: 10;
}

@media screen and (max-width: 640px) {
  #counter {
    font-size: 32px;
    top: 30px;
    left: 20px;
  }

  h1 {
    line-height: 1.25;
  }

  .heading {
    font-size: 14px;
  }

  .ca {
    font-size: 8px;
  }

  .leaderboard h2 {
    font-size: 18px;
  }

  .leaderboard ol {
    font-size: 12px;
  }

  .wallet-adapter-dropdown {
    top: 20px;
    right: 20px;
  }

  .wallet-adapter-button {
    height: 32px !important;
    padding: 0 10px !important;
    font-size: 12px !important;
  }

  .info-button {
    bottom: 20px;
    left: 20px;
  }

  .popup-content {
    padding: 20px 20px 30px;
  }

  .settings-button {
    bottom: 20px;
    right: 20px;
  }

  .info-button,
  .settings-button {
    padding: 5px 5px 3px;
  }

  .info-button img,
  .settings-button img {
    width: 16px;
  }

  .balance {
    font-size: 12px;
  }
}

@media screen and (max-width: 420px) {
  .ca {
    font-size: 6px;
  }
}